.car {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  left: 0;
}

.border-grey {
  border-color: #c0c0c0 !important;
}

.h-22 {
  height: 22% !important;
}

.opacity-50 {
  opacity: 50% !important;
}

.custom-container {
  width: 70%;
  max-width: 210px;
  margin-right: auto;
  margin-left: auto;
  overflow: hidden;
  background-size: contain !important;
}

@media (min-width: 800px) {
  .custom-container {
    max-width: 320px;
  }
}

.container-border {
  border: 1px solid #ecebeb;
}

.content-wrapper {
  margin: 0;
  height: 0;
  width: 100% !important;
}

.inner-content-wrapper {
  position: relative;
  width: 50%;
  height: 0;
  padding-bottom: 100%;
  margin: -60% auto 0;
  transform: translateY(-50%);
}

.cell-height {
  height: 0;
  width: 100%;
  margin: 0;
  display: block;
  box-sizing: border-box;
}

.custom-col {
  height: 0;
  float: left;
}
