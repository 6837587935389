#terms-privacy {
  overflow-x: auto;
}

#terms-privacy div.tab-content {
  margin-bottom: 80px;
}

#terms-privacy div.tab-content div.tab-pane > div > p {
  text-align: justify;
}

#terms-privacy .nav-tabs a.nav-item{
  font-size: 14px;
}

#terms-privacy div.terms-button {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px 15px;
  background: #fff;
  border-top: 1px solid #dee2e6;
  box-shadow: 0px -3px 10px #dee2e6;
}

#terms-privacy div.terms-button > button {
  max-width: 600px;
  margin: 0 auto;
}

@media (max-width: 400px) {
  .hide-backdrop-400 {
    margin: 0;
  }
  #terms-privacy {
    padding: 0;
  }
}
