.input-lbl {
  color: #959595;
  margin-bottom: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
}

.phone-number-row {
  border-bottom: 1px solid #afafaf !important;
}

.phone-number-row:hover {
  border-bottom: 1px solid #555555 !important;
}

.phone-number-row-invalid {
  border-bottom: 1px solid #eb0a1e !important;
}

.phone-number-row div.country-code-dropdown {
  padding-bottom: 0;
}

.phone-number-row div.country-code-dropdown button {
  border: none;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
}

.phone-number-row div.country-code-dropdown button > span {
  margin: 0;
  padding-left: 0;
}

.phone-number-row div.phone-number {
  display: flex;
  align-items: flex-end;
}

.phone-number-row div.phone-number .form-control.number-input {
  height: auto;
  border: none;
  box-shadow: none;
  font-weight: bold;
  padding: 0 0 0 5px;
}

.invalid-tel-input {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
