.custom-card,
.custom-card > .card-header {
  background: #ffffff;
  border-bottom: none;
}

.custom-card {
  padding: 0.25rem 0.25rem !important;
}

.custom-card-border {
  border: solid 1px;
  border-radius: 0;
  border-right: none;
  border-left: none;
}
