div.yaway-date > div {
  width: 100%;

  > div {

    padding-right: 0;

    input {
      padding: 0;
    }

    fieldset {
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #afafaf;
    }
  }
  > div.Mui-focused {
    fieldset.MuiOutlinedInput-notchedOutline {
      border-bottom: 1px solid #555555;
    }
  }
}