.veh-lbl {
  margin-bottom: 0;
  font-family: Source Sans Pro, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
}

.veh-inpt {
  border-radius: 0 !important;
}

.veh-card {
  background-color: #f0f0f0 !important;
  border-radius: 4px;
  padding: 25px 25px !important;
}
.veh-card p {
  margin-bottom: 0 !important;
}
.veh-card p.veh-dtl-data {
  font-weight: bold;
}
.veh-dtl-row {
  margin-left: 0 !important;
}
.veh-dtl-error {
  color: red;
}
.veh-icon {
  background-repeat: no-repeat;
  background-size: contain;
}
.veh-icon_camry {
  background-image: url('../../../assets/assistance-requests/images/vehicles/camry.png');
}
