.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest__item--active {
  background: #267dc0;
  color: #fff;
}

.geosuggest__input {
  width: 100%;
}

.geosuggest__suggests {
  list-style-type: none;
  padding-inline-start: 5px;
  border: 2px solid #267dc0;
  border-top-width: 0;
  margin-bottom: 0;
}

.geosuggest__suggests--hidden {
  border-width: 0;
}
