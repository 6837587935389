.feedback-page-centered{
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  font-size: 25px;
}

.feedback-page-icon {
  object-fit: cover;
  height: 120px;
}

.feedback-page-message{
  text-align: center;
}