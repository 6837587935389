.photo-list {
  border: 1px dashed #ced4da;
}

.add-photo-icon {
  color: #b7c0c4;
}

.add-photo-btn {
  border-radius: 4px;
  font-family: Helvetica;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  color: #555555;
}

.thumbnail {
  background-size: cover;
  background-position: center;
  height: 100%;
  border-radius: 4px;
}

.thumbnail > img {
  visibility: hidden;
}

.add-more {
  height: 100%;
  border: 2px solid #929292;
  border-radius: 4px;
}

.add-more-icon {
  height: 100%;
  width: 100%;
}

.remove {
  position: absolute;
  top: -15px;
  right: 15px;
}
