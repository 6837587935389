
.landing-icon-centered{
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
}

.center-btn{
    margin:0 auto;
    display:block;
}
