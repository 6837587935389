.incident {
  color: #eb0a1e;
  background: #ffffff;
}

.incident:active {
  border-color: #eb0a1e !important;
}

.square-card {
  height: 100px;
  width: 100px;
  border-radius: 4px;
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
}

.incident-text {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
}

.btn-next {
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 20px;
  height: 40px;
  width: 160px;
  font-size: 18px;
  background-color: #eb0a1e !important;
  border-color: transparent !important;
  border-radius: 4px;
  color: white;
  font-weight: 700 !important;
  opacity: 1;
}

.square-card:hover {
  cursor: pointer;
}
