.cam-wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background-color: black;
}

.cam-hint-wrap {
  position: absolute;
  top: 30%;
  left: 0;
  margin: 0 20% 0 20%;
  right: 0;
  color: white;
}

.cam-hint {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 40px;
  text-align: center;
}

.cam-frame {
  position: absolute;
  width: 78px;
  height: 78px;
  border-left: 5px solid #ffffff;
  border-top: 5px solid #ffffff;
}

.cam-icon-b {
  position: fixed;
  bottom: 13%;
  left: 40%;
  right: 0;
  margin: auto;
}

.cam-icon-r {
  position: fixed;
  right: 13%;
  top: 40%;
  bottom: 0;
  margin: auto;
}

.cam-icon-outer-circle {
  position: absolute;
  border-radius: 50%;
  height: 75px;
  width: 75px;
  background-color: hsla(0, 0%, 100%, 0.4);
}

.cam-icon-inner-circle {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 38px;
  height: 44px;
  width: 44px;
  background: #fff;
  margin: -22px 0 0 -22px;
}

.cam-guide {
  position: absolute;
  top: 40%;
  left: 0;
  margin: 0 20% 0 20%;
  right: 0;
  color: white;
}
