.next-btn {
  z-index: 10;
  border-color: transparent !important;
  border-radius: 4px;
  color: white;
  font-size: 18px;
  font-weight: 700 !important;
  min-width: 160px;
  max-width: 300px;
  white-space: nowrap;
  height: 40px;
  padding: 0 20px !important;
}

.next-btn-absolute {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  bottom: 25px;
}

.btn-wrapper {
  /* to stick it to bottom */
  position: sticky;
  top: 90%;
  padding-bottom: 20px !important;
  /* to center horizontally */
  display: table !important;
  margin: 40px auto 0 auto !important;
}

.next-btn-opacity {
  opacity: 0.5;
}

.btn-spinner {
  vertical-align: unset !important;
}
