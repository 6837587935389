.custom-checkbox .custom-control-input:checked:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}
.custom-checkbox .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-label {
  margin-left: 0.5rem !important;
}
