.input-lbl {
  color: #959595 !important;
  margin-bottom: 0;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0;
  text-align: left;
}

.MuiSelect-select {
  background-color: #fff !important;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: scale(1) !important;
  margin-bottom: 15px !important;
}
.txt-input {
  border: none !important;
  border-bottom: 1px solid #afafaf !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
  font-weight: bold !important;
}

.txt-input:hover {
  border: none !important;
  border-bottom: 1px solid #555555 !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
  font-weight: bold !important;
}

.txt-input_invalid {
  border: none !important;
  border-bottom: 1px solid #eb0a1e !important;
  border-radius: 0 !important;
  padding: 0 !important;
  height: auto !important;
  box-shadow: none !important;
  font-weight: bold !important;
}

textarea.form-control {
  border: 1px solid #afafaf;
}

textarea.form-control:focus {
  border-color: #afafaf;
}

textarea.form-control:hover {
  border-color: #555555;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-control:invalid:hover,
.form-control.is-invalid:hover {
  border-color: #eb0a1e;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
textarea.form-control:focus {
  box-shadow: none;
}
