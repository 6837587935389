div.yaway-container {
  width: 100%;
  max-width: 700px;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}


div.progress-bar-wrapper {
  height:auto !important;

  > div.row {
    width: 80%;
    margin: 0 auto;
  }
}