.link-status-icon {
  color: red;
  width: 8%;
  height: auto;
  margin: 30px 0;
}

.link-status-text {
  margin: 0 15%;
}
