.file-uploader {
  border: 1px solid #afafaf;
  border-radius: 3px;
  margin-top: 5px;

  > div {
    padding-top: 9%;
    padding-bottom: 9%;

    p {
      font-size: small;
      margin-bottom: 0;
    }

    .remove {
      position: absolute;
      top: -15px;
      right: 15px;
    }
  }

  .error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #dc3545;
  }
}

.file-uploader:hover {
  border-color: #555555;
}