.vertical-center {
  position: fixed !important;
  bottom: 50% !important;
}

.flow-card {
  height: 30%;
}

.flex-auto {
  flex: auto;
}

.layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.wrapper {
  position: relative;
  display: inline-block;
}

.img-shadow {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  mix-blend-mode: multiply;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.fill img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

.word-spacing-1 {
  word-spacing: 1px !important;
}

.select:hover {
  cursor: pointer;
}

.word-spacing-10 {
  word-spacing: 10vw !important;
}

.narrow-md {
  margin-left: auto !important;
  margin-right: auto !important;
}

@media (min-width: 481px) {
  .narrow-md {
    max-width: 400px !important;
  }
}
