body {
  margin: 0 !important;
}

.map-vw.map-ctrl {
  z-index: 10;
  position: absolute;
}

.map-vw.my-loc-pin {
  height: 40px;
  top: -30px; /* offset depends on icon 'height' */
  bottom: 0;
  left: 13px; /* offset depends on icon 'width' */
  right: 0;
  margin: auto;
}

.map-vw.z-index-1 {
  z-index: 1 !important;
}

.locator {
  z-index: 2;
  top: unset !important;
}

.mapboxgl-ctrl-group button {
  width: 40px !important;
  height: 40px !important;
}

.locator > div {
  border-radius: 20px !important;
  position: fixed;
  bottom: 22px;
  right: 0;
  margin-right: 20px;
}
.top-bar {
  display: flex;
  width: 100%;
}
.mapboxgl-ctrl-top-left {
  display: flex;
  width: 100%;
  justify-content: center;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23333'%3E %3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E %3Ccircle id='dot' cx='10' cy='10' r='2'/%3E %3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' display='none'/%3E %3C/svg%3E") !important;
}
.mapboxgl-ctrl button.mapboxgl-ctrl-geolocate:disabled .mapboxgl-ctrl-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='40' height='40' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg' fill='%23aaa'%3E %3Cpath d='M10 4C9 4 9 5 9 5v.1A5 5 0 0 0 5.1 9H5s-1 0-1 1 1 1 1 1h.1A5 5 0 0 0 9 14.9v.1s0 1 1 1 1-1 1-1v-.1a5 5 0 0 0 3.9-3.9h.1s1 0 1-1-1-1-1-1h-.1A5 5 0 0 0 11 5.1V5s0-1-1-1zm0 2.5a3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 1 0-7z'/%3E %3Ccircle id='dot' cx='10' cy='10' r='2'/%3E %3Cpath id='stroke' d='M14 5l1 1-9 9-1-1 9-9z' fill='red'/%3E %3C/svg%3E") !important;
}
.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  margin: 0;
}
#assistance-map .mapboxgl-ctrl-geocoder {
  border-radius: 30px !important;
  max-width: 80% !important;
  height: 33px !important;
  top: 20px !important;
  margin: 0;
}
#assistance-map .mapboxgl-ctrl-geocoder--icon-search {
  top: 6px !important;
}
#assistance-map .mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 0 !important;
}
#assistance-map .mapboxgl-ctrl-geocoder--input {
  height: inherit !important;
  padding: 6px 45px !important;
  font-size: 18px;
  font-weight: 400 !important;
  line-height: 24px;
  font-family: 'Open Sans', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  border-bottom: none !important;
}
@media screen and (min-width: 640px) {
  #assistance-map .mapboxgl-ctrl-geocoder--input {
    padding: 6px 35px !important;
  }
}

#assistance-map .mapboxgl-ctrl-geocoder--suggestion-title,
#assistance-map .mapboxgl-ctrl-geocoder--suggestion-address {
  text-align: left !important;
}

.userGuide {
  position: absolute;
  z-index: 11;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}
.userGuideText {
  color: white;
  top: 35%;
  margin: auto;
  left: 20%;
  right: 20%;
  position: absolute;
}

.tooltip-inner {
  background-color: rgba(13, 13, 13, 0.55) !important;
}

.bs-tooltip-top .arrow::before {
  border-top-color: rgba(13, 13, 13, 0.55) !important;
}

input.mapboxgl-ctrl-geocoder--input:focus {
  outline: none;
  border: 1px solid cornflowerblue;
  border-radius: 20px;
  transition: 0.1s;
}
