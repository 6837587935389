html,
body {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-flow: column;
  height: 100%;
}

#root > #header {
  flex: 0 1 auto;
}

#root > .content {
  flex: 1 1 auto;
}

#root > #footer {
  flex: 0 1 30px;
  text-align: center;
  color: #103a3c;
  font-size: 12px;
  line-height: 30px;
}
